
import { OrderInfo, Product } from 'momai'
import { getProductInfo, removeOrder } from '@/api/sales'
import { defineAsyncComponent, ref, reactive } from 'vue'
import { fromNow } from '@/utils/format'
import { useRouter } from 'vue-router'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { checkOrder, pageMyPaidOrderList } from '@/api/user/student'

export default {
  components: {
    PayForm: defineAsyncComponent(() => import('@/views/sales/components/PayForm.vue'))
  },
  setup: () => {
    const router = useRouter()
    const orderList = ref<OrderInfo[]>([])
    const curProduct = ref<Product>()
    const curOrderInfo = ref<OrderInfo>()
    const fee = (v: OrderInfo) => {
      if (v.feeType === 0) {
        return (v.status === 2 ? '微信支付:' : '待支付') + (parseFloat(v.payFee + '') / 100).toFixed(2) + '元'
      } else {
        return (v.status === 2 ? '魔豆支付:' : '待支付') + v.payFee + ' 魔豆'
      }
    }
    const params = reactive({
      show: false,
      step: 1
    })
    const totalOrderNumber = ref(-1)
    const fetch: FetchFunction = (pageData, loadEnd) => {
      pageMyPaidOrderList(pageData).then(({ records, total }) => {
        orderList.value.push(...records)
        totalOrderNumber.value = total
        loadEnd()
      })
    }
    return {
      ...useLoadList({
        fetch,
        total: totalOrderNumber,
        list: orderList
      }),
      orderList,
      fromNow,
      fee,
      curProduct,
      curOrderInfo,
      params,
      toProduct: (o: OrderInfo) => {
        const go = () => {
          const path = o.type === 0 ? `/user/productDetail/${o.productId}` : `/coursedetail/${o.productId}`
          router.push(path)
        }
        if (o.checked === 1) {
          go()
          return
        }
        checkOrder(String(o.id)).then(() => {
          go()
        })
      },
      pay: (orderInfo: OrderInfo) => {
        curOrderInfo.value = orderInfo
        getProductInfo(orderInfo.productId).then(r => {
          curProduct.value = r
          params.step = 1
          params.show = true
        })
      },
      remove: (orderInfo: OrderInfo, i: number) => {
        if (orderInfo.status !== 2) {
          removeOrder(orderInfo.id).then(() => {
            orderList.value.splice(i, 1)
          })
        }
      }
    }
  }
}
